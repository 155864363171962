import React from "react";
import Layout from "../components/layout-contents";
import styles from "../styles/review.module.css";
import Container from "../components/container";
import { graphql } from "gatsby";

const User = (props) => (
  <div className={[styles.topUser, styles.topUserTallImage].join(' ')}>
    <div className={styles.descUser}>
      <img
        src="/images/about/alexdezan/alex-logo.png"
        className={styles.logoUser}
        style={{height: 'initial'}}
        alt=""
      />
      <p className={styles.excerpt}>
        Grinding aesthetics and ripped media from Vicenza, Italy,  the cut up art of Alex De Zan.
      </p>
      <div className={styles.linkUser}>
        <h3 style={{ marginLeft: "0px", marginBottom: "0px" }}>
          <a href="mailto:alxalexdezan@gmail.com">CONTACT</a>
        </h3>
        <h3 style={{ marginLeft: "calc(2vw - (-0.5vmin))", marginBottom: "0px" }}>
          <a
            href="https://codafanzine.bandcamp.com/merch/man-poster-by-alx"
            target="_blank"
            rel="noreferrer"
          >
            SHOP
          </a>
        </h3>
      </div>
    </div>

      <img
        src="/images/about/alexdezan/alex-logo2.png"
        className={styles.imageUser}
        alt=""
      />

  </div>
);

const Post = (props) => (
  <div style={{ textAlign: "center"}}>
    <img src={props.image} alt="" />
  </div>
);

/*
  <h1>About CSS Modules</h1>
    <p>CSS Modules are cool</p>
*/
export default ({ data }) => (
  <Layout>
    <Container>
      <User></User>
      {data.allAlexDeZanJson.edges.map(({ node }) => (
        <Post image={node.image} />
      ))}
    </Container>
  </Layout>
);

export const query = graphql`
  query {
    allAlexDeZanJson {
      edges {
        node {
          image
        }
      }
    }
  }
`;
